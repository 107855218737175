const credits = {
	"Corporate Board": [
	  { title: "Editor in Chief", staff_name: "Irie Sentner" },
	  { title: "Managing Editor", staff_name: "Andrew Park" },
	  { title: "Publisher", staff_name: "Tyler Shern" },
	],
	"Engineering": [
	  { title: "Head of Engineering", staff_name: "Marian Abuhazi" },
	  { title: "Engineering Manager", staff_name: "Caroline Haoud" },
	  { title: "Senior Developer", staff_name: "Anna Reis" },
	  { title: "Associate Developer", staff_name: "Matthew Rostkowski" },
	  { title: "Associate Developer", staff_name: "Tobechi Onwuka" },
	  { title: "Trainee", staff_name: "Gabriel Guerra Trigo" },
	],
	"DEI": [{ title: "Head of Diversity, Equity, and Inclusion", staff_name: "Maria Rice" }],
	"Audio": [
	  { title: "Director of Podcasting and Audio", staff_name: "Claire Schnatterbeck" },
	  { title: "Senior Staff Reporter", staff_name: "ALICIA THEOLOGIDES RODRIGUEZ" },
	  { title: "Senior Staff Producer", staff_name: "Julia Hay" },
	  { title: "Staff Composer", staff_name: "Murat Gulcelik" },
	  { title: "Deputy Reporter", staff_name: "Sophia Cordoba" },
	],
	"Layout": [
	  { title: "Layout Editor", staff_name: "Andrada Nicolae" },
	  { title: "Layout Editor", staff_name: "Amanda Cassel" },
	  { title: "Deputy Layout Editor", staff_name: "Rachel Yuan" },
	  { title: "Layout Associate", staff_name: "Lucas Schlesinger" },
	  { title: "Layout Associate", staff_name: "Lex Meisel" },
	  { title: "Layout Associate", staff_name: "Emily Yao" },
	  { title: "Layout Associate", staff_name: "Oliver Wu" },
	],
	"University News": [
	  { title: "University News Editor", staff_name: "Isabella Ramírez" },
	  { title: "University News Editor", staff_name: "Esha Karam" },
	  { title: "Deputy University News Editor", staff_name: "Amanda Chapa" },
	  { title: "Deputy University News Editor", staff_name: "Emily Forgash" },
	  { title: "Deputy University News Editor", staff_name: "Sarah Huddleston" },
	  { title: "Deputy University News Editor", staff_name: "Sabrina Ticer-Wurr" },
	  { title: "Deputy University News Editor", staff_name: "Shea Vance" },
	  { title: "Staff Writer", staff_name: "Maya Stahl" },
	  { title: "Staff Writer", staff_name: "Caroline Capozzi" },
	],
	"Opinion": [
	  { title: "Editorial Page Editor", staff_name: "Milène Klein" },
	  { title: "Deputy Editorial Page Editor", staff_name: "Colin Roedl" },
	  { title: "Deputy Editorial Page Editor", staff_name: "Melanie Cheung" },
	  { title: "Deputy Editorial Page Editor", staff_name: "Sydney Goldson" },
	  { title: "Deputy Editorial Page Editor", staff_name: "Sofia Matson" },
	  { title: "Opinion Associate", staff_name: "Maia Berthier" },
	  { title: "Opinion Associate", staff_name: "Lloxci Lopez" },
	],
	"A&C": [
	  { title: "Arts & Culture Editor", staff_name: "Isabella Farfan" },
	  { title: "Deputy Arts & Culture Editor", staff_name: "Ariana Eftimiu" },
	  { title: "Staff Writer", staff_name: "Madison Rosenfield" },
	],
	"Cover Illustration": [
	  { title: "Deputy Illustrations Editor", staff_name: "Kelsea Petersen" },
	  { title: "Staff Illustrator", staff_name: "Jonas Ma" },
	],
	"Illustrations": [
	  { title: "Illustrations Editor", staff_name: "Ji Yoon Sim" },
	  { title: "Deputy Illustrations Editor", staff_name: "Olya Sukonrat" },
	  { title: "Deputy Illustrations Editor", staff_name: "Macy Sinreich" },
	  { title: "Deputy Illustrations Editor", staff_name: "Cassie Wang" },
	  { title: "Staff Illustrator", staff_name: "Grace Kim" },
	  { title: "Staff Illustrator", staff_name: "Yanchi Zheng" },
	  { title: "Staff Illustrator", staff_name: "Conner Sund" },
	  { title: "Staff Illustrator", staff_name: "Lizzie Melashvili" },
	],
	"Engagement": [
	  { title: "Head of Engagement", staff_name: "Jeannette Kim" },
	  { title: "Engagement Manager", staff_name: "Isaiah Colmenero" },
	  { title: "Engagement Manager", staff_name: "Sani Manomaiphibul" },
	  { title: "Engagement Manager", staff_name: "Shinjini Mukherjee" },
	],
	"Spectrum": [
	  { title: "Spectrum Editor", staff_name: "Madeline Suindah" },
	  { title: "Spectrum Editor", staff_name: "Emma Melnikov" },
	  { title: "Deputy Spectrum Editor", staff_name: "Anuthmi Waas" },
	  { title: "Staff Writer", staff_name: "Ayelet Glaser" },
	  { title: "Staff Writer", staff_name: "Jasmine Li" },
	  { title: "Staff Writer", staff_name: "Manuela Moreyra" },
	],
	"Audio": [
		  { title: "Director of Podcasting and Audio", staff_name: "Claire Schnatterbeck" },
		  { title: "Senior Staff Reporter", staff_name: "Natalie Goldberg" },
		  { title: "Senior Staff Composer", staff_name: "Eva Scholz-Carlson" },
		],
		"Graphics": [
		  { title: "Graphics Editor", staff_name: "Devon Campbell" },
		  { title: "Graphics Editor", staff_name: "Adina Cazacu-De Luca" },
		  { title: "Deputy Graphics Editor", staff_name: "Diya Nair" },
		],
		"Sports": [
		  { title: "Sports Editor", staff_name: "Jorge Hernandez" },
		  { title: "Deputy Sports Editor", staff_name: "Heather Chen" },
		  { title: "Deputy Sports Editor", staff_name: "Takashi Williams" },
		  { title: "Deputy Sports Editor", staff_name: "Brian Lee" },
		  { title: "Deputy Sports Editor", staff_name: "Sydney Stoller" },
		  { title: "Deputy Sports Editor", staff_name: "Julian Silva-Forbes" },
		  { title: "Staff Writer", staff_name: "Caleb Dye" },
		  { title: "Staff Writer", staff_name: "Aidan Hassan" },
		  { title: "Staff Writer", staff_name: "Emma Dignan" },
		],
		"Revenue": [
		  { title: "Revenue Director", staff_name: "Kristupas Pačkauskas" },
		  { title: "Deputy Revenue Director", staff_name: "Katie Zhang" },
		  { title: "Deputy Revenue Director", staff_name: "Julian Novarr" },
		  { title: "Deputy Revenue Director", staff_name: "Donovan Barcelona" },
		  { title: "Revenue Senior Associate", staff_name: "Vilanna Wang" },
		],
		"Product": [
		  { title: "Head of Product", staff_name: "Muchen Guo" },
		  { title: "Product Design Associate", staff_name: "Sarah Alsharif" },
		],
		"Crosswords": [
		  { title: "Head of Crosswords", staff_name: "Christopher Nunez" },
		  { title: "Crosswords Manager", staff_name: "Jolie L'Heureux" },
		  { title: "Crosswords Manager", staff_name: "Molly Durawa" },
		],
		"Photo": [
			{ title: "Photo Editor", staff_name: "Judy Goldstein" },
			{ title: "Deputy Photo Editor", staff_name: "Grace Li" },
			{ title: "Deputy Photo Editor", staff_name: "Gabriela Gregor-Splaver" },
			{ title: "Deputy Photo Editor", staff_name: "Pip Tsuki Carlson" },
			{ title: "Deputy Photo Editor", staff_name: "Sydney Lee" },
			{ title: "Deputy Photo Editor", staff_name: "Hannah Yanover" },
			{ title: "Deputy Photo Editor", staff_name: "Salem Maru" },
			{ title: "Deputy Photo Editor", staff_name: "Laura Mittelman" },
			{ title: "Staff Photographer", staff_name: "Genevieve Depke" },
			{ title: "Staff Photographer", staff_name: "Isabel Iino" },
		  ],
		"Copy": [
			{ title: "Head Copy Editor", staff_name: "Ronit Dhulia" },
			{ title: "Deputy Copy Editor", staff_name: "Ainhoa Petri-Hidalgo" },
			{ title: "Deputy Copy Editor", staff_name: "August Phillips" },
			{ title: "Deputy Copy Editor", staff_name: "Lanah Wyne" },
			{ title: "Deputy Copy Editor", staff_name: "Maria Levit" },
			{ title: "Deputy Copy Editor", staff_name: "Renuka Balakrishnan" },
			{ title: "Deputy Copy Editor", staff_name: "Sadie Rochman" },
			{ title: "Deputy Copy Editor", staff_name: "Yvin Shin" },
			{ title: "Associate Copy Editor", staff_name: "Emma Moralez" },
			{ title: "Associate Copy Editor", staff_name: "Kaela Aalto" },
			{ title: "Associate Copy Editor", staff_name: "Natalie DiFusco" },
			{ title: "Associate Copy Editor", staff_name: "Neena Dzur" },
			{ title: "Associate Copy Editor", staff_name: "Pun Setpattanachai" },
			{ title: "Associate Copy Editor", staff_name: "Reena Bromberg Gaber" },
			{ title: "Associate Copy Editor", staff_name: "Rose Leveen" },
			{ title: "Associate Copy Editor", staff_name: "Sally Kaye" },
			{ title: "Associate Copy Editor", staff_name: "Sophia Lee" },
			{ title: "Preslotter", staff_name: "Daniel Knorek" },
			{ title: "Preslotter", staff_name: "Driscoll Callan" },
			{ title: "Preslotter", staff_name: "Ella Ahner" },
			{ title: "Preslotter", staff_name: "Nola Gallo" },
			{ title: "Preslotter", staff_name: "Posey Chiddix" },
			{ title: "Preslotter", staff_name: "Reese Ransweiler" },
			{ title: "Preslotter", staff_name: "Vaishnavi Sirkay" },
			{ title: "Trainee", staff_name: "Campbell Beaver" },
			{ title: "Trainee", staff_name: "Cinly Mo" },
			{ title: "Trainee", staff_name: "Claudia Kim" },
			{ title: "Trainee", staff_name: "Diego Carvajal Núñez" },
			{ title: "Trainee", staff_name: "Eli Trokenheim" },
			{ title: "Trainee", staff_name: "Emily Spencer" },
			{ title: "Trainee", staff_name: "Eric Aguilar" },
			{ title: "Trainee", staff_name: "Lily Lee" },
			{ title: "Trainee", staff_name: "Mia Feizbakhsh" },
		  ],
	  };
  export { credits };
  